import { useEffect, useState } from 'react';
import { DEVICE_SIZE } from '@marty-js/design/src/utils/mq';

export type UserAgentOs = 'iOS' | 'Windows' | 'macOS' | 'Android' | 'Linux';

export const useUserAgentOs = (): UserAgentOs => {
  const [userAgentOs, setUserAgentOs] = useState<UserAgentOs>(null);
  useEffect(() => {
    const { userAgent } = window.navigator;
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setUserAgentOs('iOS');
    } else if (userAgent.includes('Win')) {
      setUserAgentOs('Windows');
    } else if (userAgent.includes('Mac')) {
      setUserAgentOs('macOS');
    } else if (userAgent.includes('Android')) {
      setUserAgentOs('Android');
    } else if (userAgent.includes('Linux')) {
      setUserAgentOs('Linux');
    }
  }, [setUserAgentOs]);

  return userAgentOs;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${DEVICE_SIZE.MEDIUM}px)`);
    if (mediaQuery.addEventListener) {
      const handleResize = () => setIsMobile(mediaQuery.matches);
      handleResize();
      mediaQuery.addEventListener('change', handleResize);
    }
  }, []);

  return isMobile;
};
