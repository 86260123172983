import parseJSON from 'date-fns/parseJSON';
import formatDistance from 'date-fns/formatDistanceToNow';

import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-GB';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const selectLocale = (locale: string): any => {
  if (locale === 'en-GB') {
    return en;
  }

  return fr;
};

export const formatDateWithTime = (dateTime: string, pattern = "dd MMMM yyyy 'à' HH'h'mm", locale = 'fr'): string => {
  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);

  return format(date, pattern, { locale: localeLanguage });
};

export const formatDate = (dateTime: string, pattern = 'dd MMMM yyyy', locale = 'fr'): string => {
  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);

  return format(date, pattern, { locale: localeLanguage });
};

export const displayDateDiff = (dateTime: string, locale = 'fr') => {
  if (!dateTime) {
    return 'n/a';
  }

  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);

  return capitalizeFirstLetter(
    formatDistance(date, {
      addSuffix: true,
      locale: localeLanguage,
      includeSeconds: false,
    }),
  );
};
