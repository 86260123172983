import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { Cta } from './cta';
import { useImageComponent, useLinkComponent } from '../utils/component';
import { useTranslation } from '../utils/translation';

export interface DiscoveryItemProps {
  image?: string;
  title?: string;
  description?: string;
  landingPage?: string;
  onClick?: () => void;
  hiddenMobile?: boolean;
}

const DiscoveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 var(--spacer-fluid);
  position: relative;

  &.mod-no-mobile {
    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        display: none;
      `,
    )}
  }
`;

const AllArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Top = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacer-s);
`;

const ImageContainer = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const Right = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.38;
`;

const Label = styled.div`
  color: ${(props) => (props.theme.isDark ? '#FF5978' : props.theme.colors.primary)};
  margin: var(--spacer-xs) 0 0;
`;

const Description = styled.div`
  line-height: 1.62;
  margin: var(--spacer-xs) 0 var(--spacer-s);
`;

const CtaContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const DiscoveryItem = (props: DiscoveryItemProps) => {
  const { onClick, title, image, description, landingPage, hiddenMobile } = props;
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();

  const t = useTranslation();

  return (
    <DiscoveryContainer className={hiddenMobile ? 'mod-no-mobile' : ''}>
      <LinkComponent href={landingPage} rel={['nofollow']} onClick={onClick} openInNewWindow>
        <AllArea />
      </LinkComponent>
      <Top>
        <ImageContainer>
          <ImageComponent
            imageId={image}
            alt={title}
            desktopHeight={60}
            desktopWidth={60}
            mobileWidth={60}
            mobileHeight={60}
          />
        </ImageContainer>
        <Right>
          <Title>{title}</Title>
          <Label>{t('design.discoveryOfTheMoment')}</Label>
        </Right>
      </Top>
      <Description>{description}</Description>
      <CtaContainer>
        <Cta className="mod-green mod-sm" title={title} href={landingPage} rel={['nofollow']} onClick={onClick}>
          {t('design.download.download')}
        </Cta>
      </CtaContainer>
    </DiscoveryContainer>
  );
};

export default DiscoveryItem;
