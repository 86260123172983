import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const HeadingTwo = styled.h2`
  font-family: var(--theme-typography-primary-font);
  font-size: 22px;
  font-weight: bold;
  line-height: 1.38;
  margin: var(--spacer-s) 0;

  &.mod-medium {
    color: var(--theme-color-medium);
  }

  &.mod-medium-black {
    color: ${(props) => (props.theme.isDark ? 'white' : '#5d6c8a')};
  }

  &.mod-discarded-x2 {
    margin: var(--spacer) 0;
  }

  &.mod-discarded {
    margin: var(--spacer-s) 0 var(--spacer);
  }

  &.mod-without-m {
    margin: 0;
  }

  &.mod-without-mt {
    margin: 0 0 var(--spacer-fluid);
  }

  &.mod-without-mb {
    margin: var(--spacer-s) 0 0;
  }

  &.mod-fat {
    color: var(--theme-color-medium);
    font-size: 30px;
    font-weight: 900;

    ${mq.gte(
      DEVICE_SIZE.LARGE,
      css`
        font-size: 48px;
      `,
    )}
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-huge {
        font-size: 30px;
      }

      &.mod-fat,
      &.mod-large {
        font-size: 48px;
      }

      span {
        font-size: 22px;
      }
    `,
  )}
`;
