import React from 'react';
import styled from 'styled-components';
import { MenuSlide } from './menu-slide';
import { useTranslation } from '../utils/translation';
import { DEVICE_SIZE, mq } from '../utils/mq';

type Section = {
  id?: string;
  title: string;
  url: string;
  imageId?: string;
  children?: Section[];
};

export type SectionNavCloudProps = {
  onlyCurrent?: boolean;
  navLinks?: Section[];
};

const SectionNavCloudContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: var(--spacer-m);

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    `
      margin: var(--spacer-s) 0 0;
    `,
  )}
`;

const SectionCloudCurrent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const SectionCloudChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacer-s);
  align-items: center;
`;

export const SectionNavCloud = ({ navLinks, onlyCurrent }: React.PropsWithChildren<SectionNavCloudProps>) => {
  const t = useTranslation();

  if (!navLinks || !navLinks.length) {
    return null;
  }

  let sections: Section[];
  if (onlyCurrent) {
    sections =
      navLinks[navLinks.length - 1]?.children?.map((child) => ({
        id: child.id,
        title: child.title,
        url: child.url,
      })) || [];
  } else {
    sections = navLinks?.flatMap(
      (navLink) =>
        navLink.children?.map((child) => ({
          id: child.id,
          title: child.title,
          url: child.url,
        })) || [],
    );
  }

  return (
    <SectionNavCloudContainer>
      {navLinks?.map((navLink, index) => {
        if (index === navLinks.length - 1) {
          return (
            <SectionCloudCurrent key={index}>
              <SectionCloudChildrenContainer>
                <MenuSlide label={t('design.categories')} title={t('design.categories')} sections={sections} />
              </SectionCloudChildrenContainer>
            </SectionCloudCurrent>
          );
        }

        return null;
      })}
    </SectionNavCloudContainer>
  );
};
