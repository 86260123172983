import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { GenericItem } from '@marty-js/api-sdk/types';
import styled, { css } from 'styled-components';
import { SectionNavCloud } from '@marty-js/design/src/molecules/section-nav-cloud';
import { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { SectionSelectionComponent } from '@marty-js/design/src/molecules/section-selection';
import { Newsletter } from '@marty-js/design/src/molecules/newsletter';
import { ItemCtaSimple } from '@marty-js/design/src/molecules/item-cta-simple';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { LinkList } from '@marty-js/design/src/molecules/link-list';
import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import DiscoveryItem from '@marty-js/design/src/molecules/discovery-item';
import { PostItemMix } from '@marty-js/design/src/molecules/post-item-mix';
import SwitchViewMode from '@marty-js/design/src/molecules/switch-view-mode';
import { PostsList } from '../../../layout/post/posts-list';
import { getHistoryDate } from '../../../atoms/date/utils';
import { Container, FullContent, MainContent, SideContent } from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { Breadcrumb } from '../../../atoms/breadcrumb';
import { SlateContentPlugin } from '../../../article-processor/plugins/slate.content';
import { Cell } from '../../../article-processor/types';
import { useMetaSeoContext, useSetMetaTitleIfNull, useSetPageNumber } from '../../../utils/metaSeoContext';
import editorSlateToText from '../../../utils/editorSlateToText';
import { Paginator } from '../../../atoms/paginator';
import { useInfiniteScroll } from '../../../utils/infinite-scroll.hooks';

import type {
  LayoutSectionProps,
  PluginArticleProcessorLight,
  PluginGenericItemByQueryConfig,
  PluginSectionInserts,
  PluginSectionList,
  PluginSectionSelection,
} from '../types';
import { LinkComponentHref } from '../../../types';
import { generateItem, postItemReducer } from '../../../utils/listing';
import { useAddServerSideZones } from '../../../ads/AdContext';
import { useIsMobile } from '../../../utils/deviceHook';

const DesktopOnly = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const SideContentBottom = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      position: sticky;
      top: 10px;
      margin-top: 30px;
    `,
  )}
`;

const Title = styled.span`
  color: var(--text-color);
  display: block;
  font-family: var(--theme-typography-primary-font);
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;

  &:first-of-type {
    margin: 30px 0 20px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

const ListItemRow = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Introduction = styled.div`
  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      margin: 3px 0;
      overflow: hidden;

      &.mod-collapsed {
        display: initial;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      max-width: 50%;
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      max-width: 800px;
    `,
  )}
`;

const Hat = styled.div`
  display: flex;
  justify-content: space-between;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: column;
      justify-content: flex-start;
    `,
  )}
`;

const DiscoveryContainer = styled.div`
  box-sizing: border-box;
  max-width: 100%;

  > * {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin: 0;
    padding: var(--spacer-s);
  }

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      max-width: 310px;
      margin: 0 0 0 auto;
    `,
  )}
`;

const ReadMore = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  text-underline-offset: 3px;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      display: none;
    `,
  )}
`;

const Featured = styled(Container)`
  margin: var(--spacer-s) 0 var(--spacer-m-fluid);
`;

const AdFullContent = styled(FullContent)`
  margin: 0;
`;

const Heading = styled.div`
  display: flex;
  gap: var(--spacer-s);
  justify-content: space-between;
`;

export type DefaultSectionData = {
  mainFlux: PluginGenericItemByQueryConfig;
  textJson: PluginArticleProcessorLight;
  inserts?: PluginSectionInserts;
  navigation?: PluginSectionList;
  sectionSelection?: PluginSectionSelection;
  topDownload: any;
  discovery?: any;
};

export const Default: React.FC<LayoutSectionProps<DefaultSectionData>> = ({ section, data, breadcrumb, pageInfo }) => {
  const t = useTranslation();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(null);
  const size = data.mainFlux?.info?.size ?? 10;
  const introductionRef = useRef<HTMLParagraphElement>(null);
  const refSideBar = useRef<HTMLElement>();
  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      size,
    }),
    [pageInfo?.section?.id, size],
  );

  const { fetchMoreData, fetchData, result, loading, called } = useGetGenericItemBySection({ variables });

  const detectorRef = useInfiniteScroll(() => {
    const from = genericItems.length;
    if (called) {
      fetchMoreData({ from });
    } else if (!loading) {
      fetchData({ variables: { ...variables, from } });
    }
  });

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems([...data.mainFlux.genericItemList, ...result.genericItemBySection.genericItems]);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);

  const hatContent: Cell = data?.textJson?.articleJson;

  const navigation = data?.navigation?.sectionList || null;
  const sectionSelection = data?.sectionSelection?.data || null;

  useSetMetaTitleIfNull(t('sdk.template.simple_listing.metaTitle', { title: section.title }));
  useSetPageNumber(pageInfo.pageNumber);

  const metaSeoContext = useMetaSeoContext();

  if (!metaSeoContext.description && hatContent) {
    metaSeoContext.description = editorSlateToText(hatContent).replace(/(^.{180}.+?)\..+/g, '$1...');
  }

  if (!metaSeoContext.description) {
    metaSeoContext.description = t('sdk.template.simple_listing.metaDescription', { title: section.title });
  }

  const { zoneSlug, keywords } = data?.discovery?.data?.data || { zoneSlug: null, keywords: null };
  const zoneDiscovery = useMemo(() => ({ zoneSlug, keywords }), [keywords, zoneSlug]);
  const dataDiscoveryOfTheMoment = useAddServerSideZones(zoneDiscovery);
  const discoveryOfTheMoment = useMemo(() => {
    if (dataDiscoveryOfTheMoment?.component?.data) {
      const componentData = dataDiscoveryOfTheMoment.component.data;
      dataDiscoveryOfTheMoment.impressionCount();

      if (!componentData?.data?.landingPage) {
        return null;
      }

      return {
        name: componentData.data?.name,
        landingPage: componentData.data?.landingPage,
        id: componentData.data?.download?.id,
        title: componentData.data?.download?.title,
        image: componentData.data?.download?.image,
        description: componentData.data?.download?.description,
        url: componentData.data?.download?.url,
        updatedAt: componentData.data?.download?.updatedAt,
      };
    }

    return null;
  }, [dataDiscoveryOfTheMoment]);

  // const h1 = metaSeoContext.titleH1 ?? section.title;

  const isMobile = useIsMobile();

  return (
    <>
      <Container>
        <FullContent>
          <Breadcrumb breadcrumb={breadcrumb} />
          <Heading>
            <HeadingOne>{section.title}</HeadingOne>
            <SwitchViewMode callback={(newMode: string) => setMode(newMode)} />
          </Heading>
          <Hat>
            {hatContent ? (
              <div>
                <Introduction
                  className={isCollapsed ? 'mod-collapsed' : ''}
                  ref={introductionRef}
                  data-testid="hat-content"
                >
                  <SlateContentPlugin cell={hatContent} />
                </Introduction>
                <ReadMore onClick={() => setIsCollapsed(!isCollapsed)}>
                  {t(`sdk.template.main_listing.${isCollapsed ? 'readLess' : 'readMore'}`)}
                </ReadMore>
              </div>
            ) : null}
            {discoveryOfTheMoment?.landingPage && (
              <DiscoveryContainer>
                <DiscoveryItem {...discoveryOfTheMoment} onClick={() => {}} />
              </DiscoveryContainer>
            )}
          </Hat>
        </FullContent>
        <AdFullContent>
          {navigation ? <SectionNavCloud navLinks={navigation} onlyCurrent /> : null}
          <Ad className="Billboard_1" desktopOnly megaban />
        </AdFullContent>
      </Container>
      <Featured>
        <MainContent>
          <PostsList>
            {genericItems?.map((genericItem: GenericItem, index: number) => {
              const previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
              const currentDate = new Date(genericItem.republishedAt);
              const label = getHistoryDate(currentDate, previousDate, t('locale'));

              if (!isMobile) {
                return generateItem(genericItem, index, t);
              }

              return (
                <PostItemMix
                  key={index}
                  className={index === 0 && !label ? 'mod-first' : ''}
                  mode={mode}
                  separator={label}
                  {...postItemReducer({ item: genericItem })}
                />
              );
            })}
          </PostsList>
          <div ref={detectorRef} aria-hidden />
          {data.mainFlux?.info ? (
            <Paginator baseUrl={pageInfo.section.url} info={data.mainFlux.info} mode="dir" />
          ) : null}
        </MainContent>
        <SideContent className="mod-gapped" ref={refSideBar}>
          {sectionSelection && (
            <SectionSelectionComponent
              title={sectionSelection?.info?.title}
              sections={sectionSelection?.sections?.sectionList}
            />
          )}
          <Ad className="HalfpageAd_1" desktopOnly />
          <Newsletter />
          <Ad className="HalfpageAd_2" desktopOnly />
          <DesktopOnly>
            {data.topDownload?.data ? (
              <div>
                <Title>{data.topDownload?.data?.info?.title}</Title>
                <ListItemRow data-testid="top-download">
                  {data.topDownload?.data?.products?.productVariantList?.map((product: any) => {
                    const imageProps = {
                      imageId: product.imageId,
                      alt: product.title,
                    };

                    const LinkProps = {
                      href: product.urlCta ?? product.url,
                      text: t('sdk.template.download.download'),
                    };

                    return (
                      <ItemCtaSimple
                        small={false}
                        href={product.url}
                        key={product.id}
                        title={product.title}
                        image={imageProps}
                        cta={LinkProps}
                        subtitle={product.title}
                      />
                    );
                  })}
                </ListItemRow>
              </div>
            ) : null}
          </DesktopOnly>
          {data?.trends && (
            <div>
              {data?.trends?.data?.info?.title ? (
                <HeadingTwo className="mod-without-mt" as="div">
                  {data.trends.data.info?.title}
                </HeadingTwo>
              ) : null}
              <ListItemRow>
                {data?.trends?.data?.links?.data?.map((link: LinkComponentHref, key: number) => {
                  return <LinkList key={key} title={link.title} url={link.url} />;
                })}
              </ListItemRow>
            </div>
          )}
          <SideContentBottom>
            <Ad className="HalfpageAd_3" desktopOnly />
          </SideContentBottom>
        </SideContent>
      </Featured>
    </>
  );
};

export default Default;
