import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mq, DEVICE_SIZE } from '../utils/mq';
import ViewLarge from '../icons/view-large';
import ViewMagazine from '../icons/view-magazine';
import ViewCompact from '../icons/view-compact';

const Modes = styled.div`
  background-color: var(--color-alt-5);
  border-radius: 50%;
  height: 44px;
  padding: 10px;
  width: 44px;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      display: none;
    `,
  )}
`;

export type ModesType = {
  callback: (mode: string) => void;
};

export const SwitchViewMode: React.FC<ModesType> = ({ callback }) => {
  const [mode, setMode] = useState(null);

  const ViewFactory = (modeView: string) => {
    switch (modeView) {
      case 'large':
        return ViewLarge;
      case 'magazine':
        return ViewMagazine;
      default:
        return ViewCompact;
    }
  };

  const handleViewChange = () => {
    const modeView = localStorage.getItem('feedMode');
    if (modeView === 'large') {
      localStorage.setItem('feedMode', 'compact');
    } else if (modeView === 'compact') {
      localStorage.setItem('feedMode', 'magazine');
    } else {
      localStorage.setItem('feedMode', 'large');
    }
    setMode(localStorage.getItem('feedMode'));
    callback(mode);
  };

  const View = ViewFactory(mode);

  useEffect(() => {
    setMode(localStorage.getItem('feedMode'));
    callback(mode);
  }, [callback, mode]);

  return (
    <Modes onClick={handleViewChange}>
      <View width={24} height={24} color="var(--text-color-alt)" />
    </Modes>
  );
};

export default SwitchViewMode;
