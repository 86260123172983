import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import Close from '../icons/close';
import { SectionNavigation } from './section-navigation';
import Unfold from '../icons/unfold';

export interface MenuSlideProps {
  label: string;
  title: string;
  hasMargin?: boolean;
  sections: SectionProps[];
}

export interface SectionProps {
  title: string;
  url: string;
  children?: SectionProps[];
}

const MenuSlideWrapper = styled.div`
  width: 100%;

  &.mod-margin {
    margin: var(--spacer);
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-margin {
        margin: 0;
      }
    `,
  )}
`;

const Overlay = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      background: ${(props) => props.theme.palette.blueMedium};
      bottom: 0;
      content: '';
      top: 0;
      left: 0;
      position: fixed;
      right: 0;
      z-index: 1000;
      transition: background 0.3s;
    `,
  )}
`;

const RefineMenu = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--spacer-s) 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
      box-sizing: content-box;
      flex-direction: column;
      justify-content: initial;
      left: -100vw;
      max-height: 100vh;
      min-height: 100vh;
      right: 100vw;
      transition: left 0.6s, right 0.3s;
      overflow-y: auto;
      position: fixed;
      z-index: -1;

      &.mod-open {
        left: 0;
        margin: 0;
        padding: 0 15px 15px;
        right: 20px;
        top: 55px;
        z-index: 10000;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      left: initial;
      position: initial;

      &.mod-open {
        max-height: initial;
        position: initial;
      }
    `,
  )}
`;

const RefineMenuTop = styled.div`
  align-items: center;
  border-bottom: 1px dashed #e5e8ee;
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
  padding: 20px 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border: none;
      margin: 0;
      padding: 0;
    `,
  )}
`;

const RefineMenuTopTitle = styled.span`
  color: ${(props) => props.theme.palette.blueMedium};
  font-size: 14px;
  font-weight: 700;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const RefineMenuClose = styled.div`
  cursor: pointer;
  font-size: 20px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const TitleCategories = styled.div`
  align-items: center;
  border-radius: 20px;
  border: solid 1px #555;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.38;
  gap: var(--spacer-s);
  padding: 7px 16px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      cursor: initial;
      display: none;
    `,
  )}
`;

const IconCategories = styled.div`
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

export const MenuSlide = (props: MenuSlideProps) => {
  const { label, title, sections, hasMargin } = props;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <MenuSlideWrapper className={hasMargin ? 'mod-margin' : ''}>
      {menuOpen && <Overlay />}
      {sections?.length > 0 && (
        <>
          <TitleCategories onClick={() => setMenuOpen(!menuOpen)}>
            <span>{label}</span>
            <IconCategories>
              <Unfold color="var(--text-color)" width={24} height={24} />
            </IconCategories>
          </TitleCategories>
          <RefineMenu className={menuOpen ? 'mod-open' : ''}>
            <RefineMenuTop>
              <RefineMenuTopTitle>{title}</RefineMenuTopTitle>
              <RefineMenuClose onClick={() => setMenuOpen(!menuOpen)}>
                <Close color="var(--theme-palette-blue-medium)" width={20} height={20} />
              </RefineMenuClose>
            </RefineMenuTop>
            <SectionNavigation sections={sections} />
          </RefineMenu>
        </>
      )}
    </MenuSlideWrapper>
  );
};
