import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Clubic from '../icons/clubic';
import { useTranslation } from '../utils/translation';
import Search from '../icons/search';

const Form = styled.form`
  background: var(--color-alt-4);
  border: solid 1px #ddd;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: var(--spacer-m) var(--spacer-s) var(--spacer-s);
`;

const Header = styled.div`
  margin: -70px auto 0;
  transform: rotate(-3deg);
`;

const Title = styled.div`
  color: var(--text-color);
  font-family: var(--theme-typography-primary-font);
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;

const Description = styled.p`
  line-height: 26px;
  margin: 0;
  text-align: center;
`;

const InputGroup = styled.div`
  align-items: center;
  border: solid 2px var(--text-color-alt);
  border-radius: 6px;
  display: flex;
  font-size: 16px;
  gap: 7px;
  padding: 8px 15px;
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
`;

const Submit = styled.button`
  background: var(--theme-palette-blue);
  border-radius: 6px;
  color: white;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s;
  padding: 12px;

  &:hover {
    background: var(--theme-palette-dark-blue);
  }
`;

const Success = styled.p`
  color: var(--theme-palette-green);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

const Error = styled.p`
  color: var(--theme-color-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const Newsletter = () => {
  const email = useRef<HTMLInputElement>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const t = useTranslation();

  const registerNewsletter = (e: any) => {
    e.preventDefault();
    fetch('https://services.ownpage.fr/v1/subscribe/288e97776c3e41e8', {
      method: 'POST',
      body: JSON.stringify({
        form_key: '8a9d9acf76074efe',
        email: email.current?.value,
      }),
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.success ? setSuccess(t('design.newsletter.success')) : setError(t('design.newsletter.error'));
      })
      .catch(() => {
        setError(t('design.newsletter.error'));
      });
  };

  return (
    <Form onSubmit={(e: any) => registerNewsletter(e)}>
      <Header>
        <Clubic width={87} height={45} />
      </Header>
      <Title>{t('design.newsletter.title')}</Title>
      <Description>{t('design.newsletter.description')}</Description>
      {success ? (
        <Success>{success}</Success>
      ) : (
        <>
          <InputGroup>
            <Search width={20} height={20} color="var(--text-color-alt)" />
            <Input type="email" placeholder={t('design.newsletter.placeholder')} ref={email} required />
          </InputGroup>
          <Submit type="submit">{t('design.newsletter.register')}</Submit>
        </>
      )}
      {error && <Error>{error}</Error>}
    </Form>
  );
};
