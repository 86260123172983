import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import CirclePlus from '../icons/circle-plus';

const Wrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
      flex-flow: wrap;
      gap: var(--spacer-s);
      justify-content: center;
    `,
  )}
`;

const Category = styled.li`
  border-bottom: solid 1px var(--theme-palette-dark-white);
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border-color: var(--color-alt-blue);
      border-radius: 24px;
      border-style: solid;
      border-width: 1px;
      padding: 0;
      transition: background-color 0.3s, color 0.3s;

      a {
        padding: var(--spacer-xs) var(--spacer-s);
      }

      &:hover {
        background-color: var(--theme-palette-blue);
        color: var(--theme-palette-white);
      }
    `,
  )}
`;

const CategoryHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  user-select: none;

  > * {
    cursor: pointer;
  }

  &:hover {
    color: var(--theme-color-primary);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-family: var(--theme-typography-default-font);
      font-weight: 400;

      &:hover {
        color: inherit;
        transition: none;
      }
    `,
  )}
`;

const CategoryHeaderArrow = styled.div`
  display: flex;
  transition: transform 0.3s ease;

  .mod-extended & {
    transform: rotate(45deg);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Items = styled.ul`
  margin: 10px 0 0;
  transition: all 0.3s ease;
  overflow: hidden;

  &.mod-closed {
    margin: 0;
    max-height: 0 !important;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Item = styled.li`
  border-left: solid 1px var(--theme-color-primary);
  padding: 5px 15px;
  transition: all 0.3s ease;

  &:hover {
    color: var(--theme-color-primary);
    transition: none;
  }
`;

export type SectionProps = {
  title?: string;
  url?: string;
  children?: SectionProps[];
};

export type SectionsProps = {
  sections?: SectionProps[];
};

export const SectionNavigation = ({ sections }: SectionsProps) => {
  const LinkComponent = useLinkComponent();
  const [areOpenMenus, setAreOpenMenu] = useState<boolean[]>([]);

  const toggleDropdown = (index: number) => {
    const areOpenMenusCopy = [...areOpenMenus];
    areOpenMenusCopy[index] = !areOpenMenusCopy[index];
    setAreOpenMenu(areOpenMenusCopy);
  };

  return (
    <Wrapper data-testid="sectionNavigations">
      {sections
        ? sections.map((section, index) => {
            if (section) {
              if (section?.children) {
                return (
                  <Category key={index} className={areOpenMenus[index] ? 'mod-extended' : ''}>
                    <CategoryHeader>
                      <LinkComponent href={section.url}>{section.title}</LinkComponent>
                      <CategoryHeaderArrow onClick={() => toggleDropdown(index)}>
                        <CirclePlus width={22} height={22} color="var(--title-foreground)" />
                      </CategoryHeaderArrow>
                    </CategoryHeader>
                    {section.children?.length ? (
                      <Items
                        className={areOpenMenus[index] ? '' : 'mod-closed'}
                        style={{ maxHeight: 40 * section.children?.length }}
                      >
                        {section.children?.map((s, subIndex) =>
                          s ? (
                            <Item key={`s-${subIndex}`}>
                              <LinkComponent href={s.url}>{s.title}</LinkComponent>
                            </Item>
                          ) : null,
                        )}
                      </Items>
                    ) : null}
                  </Category>
                );
              }

              return (
                <Category key={index}>
                  <CategoryHeader>
                    <LinkComponent href={section.url}>{section.title}</LinkComponent>
                  </CategoryHeader>
                </Category>
              );
            }

            return null;
          })
        : null}
    </Wrapper>
  );
};
