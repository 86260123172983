import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import Comment from '../icons/comment';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentCount?: number;
  tag?: string;
  authors?: AuthorProp[];
  separator?: string;
}

const Post = styled.div`
  align-items: flex-start;
  border-top: solid 1px var(--color-alt-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacer-xs);
  flex: 1 1 auto;
  justify-content: stretch;
  text-decoration: none;
  padding: var(--spacer-s) 0 var(--spacer-s);

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      gap: var(--spacer-s);
      flex-direction: row;
    `,
  )}
`;

const CommentInfo = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacer-xs);
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.62;
  min-width: var(--spacer-m);
  padding: 2px 0 0 0;
  position: relative;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      padding: 4px 0 0 0;
      font-size: 16px;
      font-weight: 400;
      width: var(--spacer-m);

      ${CommentInfo} {
        display: none;
      }
    `,
  )}
`;

const Title = styled.p`
  color: var(--theme-color-foreground);
  font-size: 16px;
  line-height: 1.38;
  transition: color 0.3s;

  &:hover {
    color: var(--theme-color-primary);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-weight: 700;
      font-size: 20px;
    `,
  )}
`;

const SeparatorDate = styled.div`
  background: var(--theme-color-background);
  text-align: center;
  position: relative;
  padding: var(--spacer-sm-fluid) 0;
  margin: -2px 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--color-alt-3);
  }
`;

const SeparatorDateLabel = styled.span`
  background: var(--theme-color-background);
  color: ${(props) => (props.theme.isDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)')};
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 var(--spacer-s);
  position: relative;
  z-index: 1;
`;

const PublishDate = (props: React.HTMLAttributes<HTMLSpanElement>) => <span {...props} />;

export const PostItemCompact: any = (props: PostItemProps) => {
  const { title, date, link, commentCount, separator } = props;

  const LinkComponent = useLinkComponent();

  return (
    <>
      {separator && (
        <SeparatorDate>
          <SeparatorDateLabel>{separator}</SeparatorDateLabel>
        </SeparatorDate>
      )}
      <LinkComponent {...link}>
        <Post>
          <LeftSide>
            <PublishDate data-testid="meta-date">{date}</PublishDate>
            {commentCount ? (
              <CommentInfo>
                <span>{commentCount}</span>
                <Comment width={20} height={20} color="var(--theme-color-primary)" />
              </CommentInfo>
            ) : null}
          </LeftSide>
          <Title data-testid="meta-label">{title}</Title>
        </Post>
      </LinkComponent>
    </>
  );
};
