import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => (theme.isDark ? theme.palette.darkBlack : '#edf0f4')};
  border-radius: 3px;
  margin: 0 0 var(--spacer-xs);
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background: ${({ theme }) => (theme.isDark ? theme.palette.darkGrey : theme.palette.darkWhite)};
  }
`;

const Link = styled.a`
  color: var(--color-alt-grey-blue);
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 9px 18px;
  text-decoration: none;

  svg {
    fill: var(--color-alt-grey-blue);
    transform: rotate(-90deg);
  }
`;

export type Props = {
  url?: string;
  title?: string;
};

export const LinkList = ({ ...props }: React.PropsWithChildren<Props>) => {
  const isExternal = props.url && !props.url.includes('clubic.com');

  return (
    <Wrapper>
      <Link target={isExternal ? '_blank' : ''} href={props.url}>
        {props.title}
        <svg width="11" height="9" viewBox="0 0 9 5" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.28571 0L4.5 3.125L7.71429 0L9 0.625L4.5 5L0 0.625L1.28571 0Z" />
        </svg>
      </Link>
    </Wrapper>
  );
};
